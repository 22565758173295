import ApiService from "@/core/services/api.service";

export const GetProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateProject = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("project", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateProject = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`project/${uuid}`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`project/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const StartProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${uuid}/start`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CompleteProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${uuid}/complete`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ProjectRelated = (uuid, type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/${type}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
